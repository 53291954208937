
import { chunk, difference, intersection, union, xor, groupBy, isSubset, take, drop, takeRight, dropRight, shuffle, zip, zipObject } from 'es-toolkit/array';
import jsConvertCase from 'js-convert-case';
import { twJoin, twMerge } from 'tailwind-merge';
import alea from 'alea';
import { createNoise2D, createNoise3D, createNoise4D } from 'simplex-noise';

import { colord, extend } from 'colord';
import a11yPlugin from 'colord/plugins/a11y';
import cmykPlugin from 'colord/plugins/cmyk';
import harmoniesPlugin from 'colord/plugins/harmonies';
import labPlugin from 'colord/plugins/lab';
import mixPlugin from 'colord/plugins/mix';

import React from 'react';

import axios from 'axios';
import { unzip } from 'unzipit';
import { marked } from 'marked';
import { createElement, Fragment, ReactElement, HTMLProps, Children, isValidElement } from 'react';
/* {"url": "https://cdn.jsdelivr.net/npm/html-react-parser@latest/dist/html-react-parser.umd.js", "import": "htmlReactParser"} */
/* {"url": "https://cdn.jsdelivr.net/npm/html-react-parser@latest/dist/html-react-parser.umd.js", "import": "htmlReactParser"} */
import htmlReactParser from 'html-react-parser';
import xmlbuilder2 from 'xmlbuilder2';
extend([mixPlugin, labPlugin, a11yPlugin, harmoniesPlugin, cmykPlugin])
export function absolute(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.abs(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.abs(input);
}


export function arrayAppend(array, element, elementType) {
    if (!Array.isArray(array)) {
        return [];
    }
    if (elementType === "string") {
        return [...array, String(element)];
    }
    if (elementType === "number") {
        return [...array, Number(element)];
    }
    if (elementType === "boolean") {
        if (String(element).toLowerCase() === "false") {
            return [...array, false];
        }
        else if (String(element).toLowerCase() === "true") {
            return [...array, true];
        }
        return [...array, Boolean(element)];
    }
    if (elementType === "raw") {
        return [...array, element];
    }
    return [];
}


export function arrayBoolean(firstArray, secondArray, operation) {
    switch (operation) {
        case "intersection":
            return intersection(firstArray, secondArray);
        case "difference":
            return difference(firstArray, secondArray);
        case "union":
            return union(firstArray, secondArray);
        case "xor":
            return xor(firstArray, secondArray);
        default:
            return firstArray;
    }
}


export function arrayChunk(array, number, method) {
    if (!Array.isArray(array)) {
        return [];
    }
    if (method === "bySize") {
        return chunk(array, number);
    }
    if (method === "byNumber") {
        const chunkSize = Math.floor(array.length / number);
        const remainder = array.length % number;
        const result = [];
        let start = 0;
        for (let i = 0; i < number; i++) {
            const end = start + chunkSize + (i < remainder ? 1 : 0);
            result.push(array.slice(start, end));
            start = end;
        }
        return result;
    }
    return [array];
}


export function arrayConcat(arrays) {
    if (!arrays || !Array.isArray(arrays)) {
        return [];
    }
    return arrays.reduce((acc, array) => {
        return acc.concat(array);
    }, []);
}


export function arrayDedup(array, dedupKey) {
    if (!Array.isArray(array)) {
        return [];
    }
    const result = [];
    const existing = /* @__PURE__ */ new Set();
    array.forEach((item) => {
        const value = typeof item === "object" && dedupKey ? getObjectValueByPath(item, dedupKey) : item;
        if (!existing.has(value)) {
            existing.add(value);
            result.push(item);
        }
    });
    return result;
}


export function arrayFilter(array, filterElements, useCustomFilter = false, reverseCustomFilter = false, removeNull = false, removeUndefined = false, removeEmptyString = false, removeFalse = false, removeZero = false, removeNaN = false, removeInfinity = false, removeNegativeInfinity = false, removeWhitespace = false, removeEmptyArray = false, removeEmptyObject = false) {
    if (!Array.isArray(array)) {
        return [];
    }
    let filters = [];
    if (Array.isArray(filterElements)) {
        filters = filterElements;
    }
    else if (filterElements !== void 0) {
        filters = [filterElements];
    }
    const filteredArray = array;
    return filteredArray.filter((element) => {
        const elementType = typeof element;
        let keep = true;
        if (removeNull && element === null) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeUndefined && element === void 0) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeEmptyString && elementType === "string" && element === "") {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeFalse && elementType === "boolean" && element === false) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeZero && elementType === "number" && element === 0) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeNaN && elementType === "number" && Number.isNaN(element)) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeInfinity && elementType === "number" && element === Infinity) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeNegativeInfinity && elementType === "number" && element === -Infinity) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeWhitespace && elementType === "string" && element.trim() === "") {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeEmptyArray && Array.isArray(element) && element.length === 0) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (removeEmptyObject && elementType === "object" && element && !Array.isArray(element) && Object.keys(element).length === 0) {
            keep = false;
        }
        if (!keep) {
            return false;
        }
        if (useCustomFilter) {
            if (reverseCustomFilter) {
                keep = filters.includes(element);
            }
            else {
                keep = !filters.includes(element);
            }
        }
        return keep;
    });
}


export function arrayFlatten(array, maxDepth) {
    if (!Array.isArray(array)) {
        return [];
    }
    return array.flat(Math.floor(maxDepth));
}


export function arrayGetElement(array, index, defaultValue) {
    if (!Array.isArray(array)) {
        return defaultValue;
    }
    if (index < 0) {
        index = array.length + index;
    }
    return index >= 0 && index < array.length ? array[index] : defaultValue;
}


export function arrayGroupBy(array, key) {
    return groupBy(array, (item) => String(item[key]));
}


export function arrayIncludes(array, element) {
    if (!Array.isArray(array)) {
        return false;
    }
    if (Array.isArray(element)) {
        return isSubset(array, element);
    }
    return array.includes(element);
}


export function arrayInsertItem(array, index, element) {
    if (!Array.isArray(array)) {
        return [];
    }
    if (index < 0) {
        index = array.length + index;
    }
    if (index < 0) {
        index = 0;
    }
    if (index > array.length) {
        index = array.length;
    }
    const result = [...array];
    result.splice(index, 0, element);
    return result;
}


export function arrayLength(array) {
    if (!Array.isArray(array)) {
        return 0;
    }
    return array.length;
}


export function arrayRange(start, end, step) {
    if (step === 0) {
        return [];
    }
    const length = Math.floor((end - start) / step);
    return Array.from({ length }, (_, i) => start + i * step);
}


export function arrayRemoveItem(array, index) {
    if (!Array.isArray(array)) {
        return array;
    }
    if (index < 0 || index >= array.length) {
        return array;
    }
    return [...array.slice(0, index), ...array.slice(index + 1)];
}


export function arrayReverse(array) {
    if (!Array.isArray(array)) {
        return [];
    }
    return [...array].reverse();
}


export function arraySetElement(array, index, element) {
    if (!Array.isArray(array)) {
        return [];
    }
    if (index < 0) {
        index = array.length + index;
    }
    if (index < 0 || index >= array.length) {
        throw new Error("Index out of bounds");
    }
    return [...array.slice(0, index), element, ...array.slice(index + 1)];
}


export function arrayShuffle(array) {
    if (!Array.isArray(array)) {
        return [];
    }
    return shuffle([...array]);
}


export function arraySlice(array, startIndex, endIndex) {
    if (!Array.isArray(array)) {
        return [];
    }
    return array.slice(startIndex, endIndex);
}


export function arraySort(array, sortKey, ascending = true) {
    if (!Array.isArray(array)) {
        return [];
    }
    const sign = ascending ? 1 : -1;
    const result = [...array];
    return result.sort((a, b) => {
        if (typeof a === "object" && typeof b === "object" && sortKey) {
            const valA = a[sortKey];
            const valB = b[sortKey];
            if (typeof valA === "string" && typeof valB === "string") {
                return sign * valA.localeCompare(valB);
            }
            return sign * (Number(valA) - Number(valB));
        }
        if (typeof a === "string" && typeof b === "string") {
            return sign * a.localeCompare(b);
        }
        return sign * (Number(a) - Number(b));
    });
}


export function arrayTake(array, direction, n, inverse) {
    if (direction === "first") {
        return inverse ? drop(array, n) : take(array, n);
    }
    else if (direction === "last") {
        return inverse ? dropRight(array, n) : takeRight(array, n);
    }
    return array;
}


export function arrayZip(arrays, fillValue, enableFill) {
    if (!Array.isArray(arrays)) {
        return [];
    }
    if (!enableFill) {
        return zip(...arrays);
    }
    const maxArrayLength = Math.max(...arrays.map((array) => array.length));
    const filledArrays = arrays.map((array) => {
        return [...array, ...repeat(fillValue, maxArrayLength - array.length)];
    });
    return zip(...filledArrays);
}


export function arrayZipObject(keys, values, fillValue, enableFill) {
    if (!Array.isArray(keys) || !Array.isArray(values)) {
        return {};
    }
    const stringKeys = keys.map((key) => String(key));
    if (!enableFill) {
        return zipObject(stringKeys, values);
    }
    const maxLength = Math.max(keys.length, values.length);
    const filledValues = [...values];
    while (filledValues.length < maxLength) {
        filledValues.push(fillValue);
    }
    return zipObject(stringKeys, filledValues);
}


export function buildPath(baseRoute = "", route = "") {
    const base = baseRoute.replace(/^\/+|\/+$/g, "");
    const path = route.replace(/^\/+|\/+$/g, "");
    const parts = [base, path].filter(Boolean);
    return parts.length > 0 ? "/" + parts.join("/") : "";
}


export function buildSitemap(baseUrl, paths) {
    const normalizedBaseUrl = baseUrl.replace(/\/$/, "");
    const doc = xmlbuilder2.create({ version: "1.0", encoding: "UTF-8" }).ele("urlset", { xmlns: "http://www.sitemaps.org/schemas/sitemap/0.9" });
    paths.forEach((path) => {
        const normalizedPath = path.replace(/^\//, "");
        doc.ele("url").ele("loc").txt(`${normalizedBaseUrl}/${normalizedPath}`).up().ele("changefreq").txt("weekly").up().ele("priority").txt("0.5");
    });
    return doc.end({ prettyPrint: true });
}


export function ceiling(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.ceil(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.ceil(input);
}


export function clamp(input, min, max) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.min(Math.max(x, min), max) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.min(Math.max(input, min), max);
}


export function colorAnalysis(first = "black", second = "white", method = "isValid") {
    const colorFirst = colord(first);
    const colorSecond = colord(second);
    console.log("first and second", first, second);
    switch (method) {
        case "isValid":
            return colorFirst.isValid();
        case "isReadable":
            return colorFirst.isReadable(colorSecond);
        case "isDark":
            return colorFirst.isDark();
        case "isLight":
            return colorFirst.isLight();
        case "isEqual":
            return colorFirst.isEqual(colorSecond);
        case "alpha":
            return colorFirst.alpha();
        case "hue":
            return colorFirst.hue();
        case "brightness":
            return colorFirst.brightness();
        case "luminance":
            return colorFirst.luminance();
        case "contrast":
            return colorFirst.contrast(colorSecond);
        case "delta":
            return colorFirst.delta(colorSecond);
        default:
            return void 0;
    }
}


export function colorAverage(colors, avergeInColorSpace = "hsl", colorSpace = "rgb", outputFormat = "string") {
    const colorInternals = colors.map((color) => {
        return colord(color);
    });
    let averageColor = colord("black");
    if (avergeInColorSpace === "hsl") {
        const accumulatedColor = { h: 0, s: 0, l: 0, a: 0 };
        colorInternals.forEach((color) => {
            accumulatedColor.h += color.toHsl().h;
            accumulatedColor.s += color.toHsl().s;
            accumulatedColor.l += color.toHsl().l;
            accumulatedColor.a += color.toHsl().a;
        });
        averageColor = colord({
            h: accumulatedColor.h / colorInternals.length,
            s: accumulatedColor.s / colorInternals.length,
            l: accumulatedColor.l / colorInternals.length,
            a: accumulatedColor.a / colorInternals.length
        });
    }
    else if (avergeInColorSpace === "rgb") {
        const accumulatedColor = { r: 0, g: 0, b: 0, a: 0 };
        colorInternals.forEach((color) => {
            accumulatedColor.r += color.toRgb().r;
            accumulatedColor.g += color.toRgb().g;
            accumulatedColor.b += color.toRgb().b;
            accumulatedColor.a += color.toRgb().a;
        });
        averageColor = colord({
            r: accumulatedColor.r / colorInternals.length,
            g: accumulatedColor.g / colorInternals.length,
            b: accumulatedColor.b / colorInternals.length,
            a: accumulatedColor.a / colorInternals.length
        });
    }
    return colorConvert(averageColor.toHex(), colorSpace, outputFormat);
}


export function colorConvert(color, colorSpace = "rgb", outputFormat = "string") {
    const colorInternal = colord(color || "black");
    if (outputFormat === "object") {
        switch (colorSpace) {
            case "rgb":
                return colorInternal.toRgb();
            case "hsl":
                return colorInternal.toHsl();
            case "hsv":
                return colorInternal.toHsv();
            case "lab":
                return colorInternal.toLab();
            case "lch":
                return colorInternal.toLch();
            case "cmyk":
                return colorInternal.toCmyk();
            default:
                return colorInternal.toRgb();
        }
    }
    else {
        switch (colorSpace) {
            case "rgb":
                return colorInternal.toRgbString();
            case "hsl":
                return colorInternal.toHslString();
            case "hsv": {
                const hsv = colorInternal.toHsv();
                const h = Math.round(hsv.h || 0);
                const s = Math.round((hsv.s || 0) * 100);
                const v = Math.round((hsv.v || 0) * 100);
                return `hsv(${h}, ${s}%, ${v}%)`;
            }
            case "hex":
                return colorInternal.toHex();
            default:
                return colorInternal.toRgbString();
        }
    }
}


export function colorHarmonies(color = "black", colorSpace = "rgb", outputFormat = "string", harmonyType = "complementary") {
    const colorInternal = colord(color);
    return colorInternal.harmonies(harmonyType).map((color2) => colorConvert(color2.toHex(), colorSpace, outputFormat));
}


export function colorMix(first = "black", second = "white", mix = 0.5, colorSpace = "rgb", outputFormat = "string") {
    const colorInternal = colord(first).mix(colord(second), mix);
    return colorConvert(colorInternal.toHex(), colorSpace, outputFormat);
}


export function colorShift(color = "black", hueShift = 0, saturationShift = 0, lightnessShift = 0, colorSpace = "rgb", outputFormat = "string") {
    let colorInternal = colord(color);
    if (saturationShift > 0) {
        colorInternal = colorInternal.saturate(saturationShift);
    }
    else if (saturationShift < 0) {
        colorInternal = colorInternal.desaturate(-saturationShift);
    }
    if (lightnessShift > 0) {
        colorInternal = colorInternal.lighten(lightnessShift);
    }
    else if (lightnessShift < 0) {
        colorInternal = colorInternal.darken(-lightnessShift);
    }
    colorInternal = colorInternal.rotate(hueShift);
    return colorConvert(colorInternal.toHex(), colorSpace, outputFormat);
}


export function colorVariants(color = "black", variantType = "tint", steps = 5, colorSpace = "rgb", outputFormat = "string") {
    const colorInternal = colord(color);
    switch (variantType) {
        case "tint":
            return colorInternal.tints(steps).map((color2) => colorConvert(color2.toHex(), colorSpace, outputFormat));
        case "shade":
            return colorInternal.shades(steps).map((color2) => colorConvert(color2.toHex(), colorSpace, outputFormat));
        case "tone":
            return colorInternal.tones(steps).map((color2) => colorConvert(color2.toHex(), colorSpace, outputFormat));
        default:
            return [colorConvert(colorInternal.toHex(), colorSpace, outputFormat)];
    }
}


export function compare(operation, left, right) {
    switch (operation) {
        case "equal":
            return left === right;
        case "notEqual":
            return left !== right;
        case "lessThan":
            return left < right;
        case "lessThanOrEqual":
            return left <= right;
        case "greaterThan":
            return left > right;
        case "greaterThanOrEqual":
            return left >= right;
        default:
            return false;
    }
}


export function complement(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? 1 - x : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return 1 - input;
}


export function createObject(keyValuePairs) {
    if (!Array.isArray(keyValuePairs)) {
        return {};
    }
    return keyValuePairs.reduce((result, kvPair) => {
        if (typeof kvPair === "object" && kvPair !== null) {
            return { ...result, ...kvPair };
        }
        return result;
    }, {});
}


export function createRoutesFromReactChildren(children) {
    const routes = [];
    function createRouteFromReactElement(element) {
        const type = element.type;
        const route = Object.assign({}, type.defaultProps, element.props);
        if (route.children) {
            const childRoutes = createRoutesFromReactChildren(route.children);
            if (childRoutes.length) {
                route.childRoutes = childRoutes;
                childRoutes.forEach((childRoute) => {
                    if (childRoute.path && route.path) {
                        childRoute.path = childRoute.path.replace(route.path, "");
                    }
                });
            }
            delete route.children;
        }
        return route;
    }
    Children.forEach(children, (element) => {
        if (isValidElement(element)) {
            const type = element.type;
            if (type.createRouteFromReactElement) {
                const route = type.createRouteFromReactElement(element);
                if (route) {
                    routes.push(route);
                }
            }
            else {
                routes.push(createRouteFromReactElement(element));
            }
        }
    });
    return routes;
}


export function cssProperty(property, value) {
    if (typeof property !== "string" || !property) {
        return {};
    }
    return {
        [property]: value || ""
    };
}


export function cssStringToObject(cssString) {
    if (typeof cssString !== "string") {
        return {};
    }
    return cssString.split(";").map((x) => x.split(":")).reduce((acc, [key, value]) => {
        if (!key || !value) {
            return acc;
        }
        acc[key.trim()] = value.trim();
        return acc;
    }, {});
}


export function decrement(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? x - 1 : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return input - 1;
}


export function divide(first, second) {
    if (Array.isArray(first) && Array.isArray(second)) {
        return first.map((x, i) => typeof x === "number" && typeof second[i] === "number" ? x / second[i] : 0);
    }
    if (Array.isArray(first)) {
        return first.map((x) => typeof x === "number" && typeof second === "number" ? x / second : 0);
    }
    if (Array.isArray(second)) {
        return second.map((x) => typeof x === "number" && typeof first === "number" ? first / x : 0);
    }
    if (typeof first !== "number" || typeof second !== "number") {
        throw new Error("Invalid input");
    }
    return first / second;
}


export function dot(input) {
    return input;
}


export function exponent(base, exponent2) {
    if (Array.isArray(base) && Array.isArray(exponent2)) {
        return base.map((x, i) => typeof x === "number" && typeof exponent2[i] === "number" ? Math.pow(x, exponent2[i]) : 0);
    }
    if (Array.isArray(base)) {
        return base.map((x) => typeof x === "number" && typeof exponent2 === "number" ? Math.pow(x, exponent2) : 0);
    }
    if (Array.isArray(exponent2)) {
        return exponent2.map((x) => typeof x === "number" && typeof base === "number" ? Math.pow(base, x) : 0);
    }
    if (typeof base !== "number" || typeof exponent2 !== "number") {
        throw new Error("Invalid input");
    }
    return Math.pow(base, exponent2);
}


export function extractPathsFromRouter(routes) {
    const convertedRoutes = createRoutesFromReactChildren(routes);
    const paths = parseRoutes(convertedRoutes);
    return paths;
}


export function floor(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.floor(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.floor(input);
}


export function fraction(input) {
    if (Array.isArray(input)) {
        return input.map((x) => {
            if (typeof x !== "number") {
                return 0;
            }
            if (x < 0) {
                const positiveNumber = Math.abs(x);
                return positiveNumber - Math.floor(positiveNumber);
            }
            else {
                return x - Math.floor(x);
            }
        });
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    if (input < 0) {
        const positiveNumber = Math.abs(input);
        return positiveNumber - Math.floor(positiveNumber);
    }
    else {
        return input - Math.floor(input);
    }
}


export function gate(first, second, operator) {
    let result = false;
    switch (operator) {
        case "and":
            result = Boolean(first && second);
            break;
        case "or":
            result = Boolean(first || second);
            break;
        case "not":
            result = !first;
            break;
        case "xor":
            result = first !== second;
            break;
        case "nand":
            result = !(first && second);
            break;
        case "nor":
            result = !(first || second);
            break;
        case "xnor":
            result = first === second;
            break;
        default:
            result = false;
    }
    return result;
}


export function getObjectValueByPath(obj, path) {
    return path.split(".").reduce((acc, part) => acc && acc[part] ? acc[part] : void 0, obj);
}


export function handleSpecialChars(str) {
    return str.replace(/(?<!\\)\\n/g, "\n").replace(/(?<!\\)\\t/g, "	").replace(/\\\\/g, "\\");
}


export function ifElse(condition, trueValue, falseValue) {
    if (condition) {
        return trueValue;
    }
    else {
        return falseValue;
    }
}


export function importAsset(url, thumbnailUrl, name, fileId) {
    return {
        url,
        thumbnailUrl,
        name,
        fileId
    };
}


export function increment(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? x + 1 : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return input + 1;
}


export function isFalsy(inputValue, checkNull, checkUndefined, checkFalse, checkZero, checkNaN, checkEmptyString) {
    if (checkNull && inputValue === null) {
        return true;
    }
    if (checkUndefined && inputValue === void 0) {
        return true;
    }
    if (checkFalse && inputValue === false) {
        return true;
    }
    if (checkZero && inputValue === 0) {
        return true;
    }
    if (checkNaN && Number.isNaN(inputValue)) {
        return true;
    }
    if (checkEmptyString && inputValue === "") {
        return true;
    }
    return false;
}


export function isFinite(input) {
    if (typeof input !== "number") {
        throw new Error("Invalid input");
    }
    return Number.isFinite(input);
}


export function isInteger(input) {
    if (typeof input !== "number") {
        throw new Error("Invalid input");
    }
    return Number.isInteger(input);
}


export function isNan(input) {
    if (typeof input !== "number") {
        throw new Error("Invalid input");
    }
    return Number.isNaN(input);
}


export function isNullish(inputValue, checkNull, checkUndefined) {
    if (checkNull && inputValue === null) {
        return true;
    }
    if (checkUndefined && inputValue === void 0) {
        return true;
    }
    return false;
}


export function isReactChildren(object) {
    return isValidChild(object) || Array.isArray(object) && object.every(isValidChild);
}


export function isValidChild(object) {
    return object === null || isValidElement(object);
}


export function isValidURL(url) {
    try {
        new URL(String(url));
        return true;
    }
    catch (e) {
        return false;
    }
}


export function jsonParse(string) {
    if (string === void 0) {
        return void 0;
    }
    try {
        return JSON.parse(string);
    }
    catch (error) {
        return void 0;
    }
}


export function jsonStringify(object) {
    try {
        return JSON.stringify(object, null, 2);
    }
    catch (error) {
        return "";
    }
}


export async function loadAsset(url, responseType = "blob", encoding = "utf-8", maxRedirects = 5) {
    if (!isValidURL(url)) {
        throw new Error(`Invalid URL: ${url}`);
    }
    const response = await axios.get(url, { responseType, responseEncoding: encoding, maxRedirects });
    return response.data;
}


export function loopEnd(iterationOutput) {
    if (Array.isArray(iterationOutput)) {
        return iterationOutput;
    }
    return [];
}


export function loopStart(input, index) {
    return {
        input,
        index
    };
}


export function maximum(first, second) {
    if (Array.isArray(first) && Array.isArray(second)) {
        return first.map((x, i) => typeof x === "number" && typeof second[i] === "number" ? Math.max(x, second[i]) : 0);
    }
    if (Array.isArray(first)) {
        return first.map((x) => typeof x === "number" && typeof second === "number" ? Math.max(x, second) : 0);
    }
    if (Array.isArray(second)) {
        return second.map((x) => typeof x === "number" && typeof first === "number" ? Math.max(first, x) : 0);
    }
    if (typeof first !== "number" || typeof second !== "number") {
        throw new Error("Invalid input");
    }
    return Math.max(first, second);
}


export function mdxToHtml(md, wrapperComponent, classNames, id) {
    try {
        const html = marked.parse(md, { async: false });
        const wrapperTag = wrapperComponent.trim();
        const wrapper = wrapperTag ? wrapperTag : Fragment;
        const props = wrapper === Fragment ? {} : { className: classNames, id };
        return createElement(wrapper, props, htmlReactParser(html));
    }
    catch (e) {
        console.error(e);
        return null;
    }
}


export function mergeAsArray(elements) {
    if (!Array.isArray(elements)) {
        return [];
    }
    return [...elements];
}


export function mergeTailwindStyles(strings, mergeOnly) {
    if (mergeOnly) {
        return twJoin(...strings);
    }
    else {
        return twMerge(...strings);
    }
}


export function minimum(first, second) {
    if (Array.isArray(first) && Array.isArray(second)) {
        return first.map((x, i) => typeof x === "number" && typeof second[i] === "number" ? Math.min(x, second[i]) : 0);
    }
    if (Array.isArray(first)) {
        return first.map((x) => typeof x === "number" && typeof second === "number" ? Math.min(x, second) : 0);
    }
    if (Array.isArray(second)) {
        return second.map((x) => typeof x === "number" && typeof first === "number" ? Math.min(first, x) : 0);
    }
    if (typeof first !== "number" || typeof second !== "number") {
        throw new Error("Invalid input");
    }
    return Math.min(first, second);
}


export function minus(first, second) {
    if (Array.isArray(first) && Array.isArray(second)) {
        return first.map((x, i) => typeof x === "number" && typeof second[i] === "number" ? x - second[i] : 0);
    }
    if (Array.isArray(first)) {
        return first.map((x) => typeof x === "number" && typeof second === "number" ? x - second : 0);
    }
    if (Array.isArray(second)) {
        return second.map((x) => typeof x === "number" && typeof first === "number" ? first - x : 0);
    }
    if (typeof first !== "number" || typeof second !== "number") {
        throw new Error("Invalid input");
    }
    return first - second;
}


export function multiply(a, b) {
    if (typeof a === "number" && typeof b === "number") {
        return a * b;
    }
    if (Array.isArray(a) && Array.isArray(b)) {
        return a.map((x, i) => typeof x === "number" && typeof b[i] === "number" ? x * b[i] : 0);
    }
    if (Array.isArray(a)) {
        return a.map((x) => typeof x === "number" && typeof b === "number" ? x * b : 0);
    }
    if (Array.isArray(b)) {
        return b.map((x) => typeof x === "number" && typeof a === "number" ? x * a : 0);
    }
    return 0;
}


export function negate(a) {
    if (typeof a === "number") {
        return -a;
    }
    if (Array.isArray(a)) {
        return a.map((x) => -x);
    }
    return a;
}


export function noOp(input) {
    return input;
}


export function noise(input, seed, dimension, x = 0, y = 0, z = 0, w = 0, scale = 1) {
    if (Array.isArray(input)) {
        if (input.length === 1) {
            x = input[0];
            y = 0;
        }
        else if (input.length === 2) {
            x = input[0];
            y = input[1];
        }
        else if (input.length === 3) {
            x = input[0];
            y = input[1];
            z = input[2];
        }
        else if (input.length >= 4) {
            x = input[0];
            y = input[1];
            z = input[2];
            w = input[3];
        }
    }
    let noiseFn;
    if (dimension === 2) {
        noiseFn = createNoise2D(alea(seed));
        return noiseFn(x, y) * scale;
    }
    else if (dimension === 3) {
        noiseFn = createNoise3D(alea(seed));
        return noiseFn(x, y, z) * scale;
    }
    else if (dimension === 4) {
        noiseFn = createNoise4D(alea(seed));
        return noiseFn(x, y, z, w) * scale;
    }
    return 0;
}


export function objectAddKey(object, key, value, overwrite = false) {
    if (typeof object !== "object" || object === null) {
        return {};
    }
    const result = { ...object };
    if (overwrite || !(key in result)) {
        result[key] = value;
    }
    return result;
}


export function objectDeleteKey(object, key) {
    if (typeof object !== "object" || object === null) {
        return {};
    }
    const result = { ...object };
    delete result[key];
    return result;
}


export function objectEntries(object, arrayType) {
    if (object === void 0 || typeof object !== "object") {
        return [];
    }
    if (arrayType === "keyValuePair") {
        return Array.from(Object.entries(object));
    }
    if (arrayType === "key") {
        return Array.from(Object.keys(object));
    }
    if (arrayType === "value") {
        return Array.from(Object.values(object));
    }
    return [];
}


export function objectFilter(object, customKeyFilters, customValueFilters, useCustomKeyFilter = false, reverseCustomKeyFilter = false, useCustomValueFilter = false, reverseCustomValueFilter = false, removeEmptyStringKey = false, removeNull = false, removeUndefined = false, removeEmptyString = false, removeWhitespace = false, removeZero = false, removeNaN = false, removeFalse = false, removeEmptyArray = false, removeEmptyObject = false, removeInfinity = false, removeNegativeInfinity = false) {
    const newObj = {};
    let keyFilters = [];
    let valueFilters = [];
    if (Array.isArray(customKeyFilters)) {
        keyFilters = customKeyFilters;
    }
    else if (typeof customKeyFilters === "string") {
        keyFilters = [customKeyFilters];
    }
    if (Array.isArray(customValueFilters)) {
        valueFilters = customValueFilters;
    }
    else if (customValueFilters !== void 0) {
        valueFilters = [customValueFilters];
    }
    keyFilters = keyFilters.filter((key) => key !== null && key !== void 0 && key !== "");
    valueFilters = valueFilters.filter((value) => value !== null && value !== void 0 && value !== "");
    Object.keys(object).forEach((key) => {
        const value = object[key];
        const valueType = typeof value;
        const newKey = key;
        const newValue = value;
        if (useCustomKeyFilter && keyFilters && keyFilters.length > 0) {
            if (reverseCustomKeyFilter) {
                if (keyFilters.includes(key)) {
                    return;
                }
            }
            else {
                if (!keyFilters.includes(key)) {
                    return;
                }
            }
        }
        if (useCustomValueFilter && valueFilters && valueFilters.length > 0) {
            if (reverseCustomValueFilter) {
                if (valueFilters.includes(value)) {
                    return;
                }
            }
            else {
                if (!valueFilters.includes(value)) {
                    return;
                }
            }
        }
        if (removeEmptyStringKey && key === "") {
            return;
        }
        if (removeNull && value === null) {
            return;
        }
        if (removeUndefined && value === void 0) {
            return;
        }
        if (removeEmptyString && valueType === "string" && value === "") {
            return;
        }
        if (removeWhitespace && valueType === "string" && value.trim() === "") {
            return;
        }
        if (removeZero && valueType === "number" && value === 0) {
            return;
        }
        if (removeNaN && valueType === "number" && Number.isNaN(value)) {
            return;
        }
        if (removeFalse && value === false) {
            return;
        }
        if (removeEmptyArray && Array.isArray(value) && value.length === 0) {
            return;
        }
        if (removeEmptyObject && valueType === "object" && Object.keys(value).length === 0) {
            return;
        }
        if (removeInfinity && valueType === "number" && value === Infinity) {
            return;
        }
        if (removeNegativeInfinity && valueType === "number" && value === -Infinity) {
            return;
        }
        newObj[newKey] = newValue;
    });
    return newObj;
}


export function objectFromEntries(entries) {
    return Object.fromEntries(entries);
}


export function objectGetKey(object, key, defaultValue) {
    if (typeof object !== "object" || object === null) {
        return defaultValue;
    }
    const hasKey = key in object;
    if (!hasKey) {
        return defaultValue;
    }
    if (typeof object[key] === "function") {
        return object[key].bind(object);
    }
    else {
        return object[key];
    }
}


export function objectMerge(baseObject, mergingObject) {
    if (typeof baseObject !== "object" || typeof mergingObject !== "object") {
        return {};
    }
    return { ...baseObject, ...mergingObject };
}


export function objectRenameKey(object, oldKey, newKey) {
    if (typeof object !== "object" || object === null) {
        return {};
    }
    const result = { ...object };
    if (oldKey in result) {
        result[newKey] = result[oldKey];
        delete result[oldKey];
    }
    return result;
}


export function parseRoutes(routes = [], basePath = "") {
    const paths = /* @__PURE__ */ new Set();
    function addRoute(route, parentPath = "") {
        const fullPath = buildPath(parentPath, route.path);
        if (fullPath) {
            paths.add(fullPath);
        }
        if (route.childRoutes && route.childRoutes.length > 0) {
            route.childRoutes.forEach((child) => addRoute(child, fullPath));
        }
    }
    if (Array.isArray(routes)) {
        routes.forEach((route) => addRoute(route));
    }
    else {
        addRoute(routes);
    }
    return Array.from(paths);
}


export function passThrough(input) {
    return input;
}


export function plus(a, b) {
    return a + b;
}


export function remainder(a, b) {
    if (typeof a !== "number" || typeof b !== "number") {
        throw new Error("Invalid input");
    }
    return a % b;
}


export function remap(input, originalMin, originalMax, newMin, newMax) {
    if (typeof originalMin !== "number" || typeof originalMax !== "number" || typeof newMin !== "number" || typeof newMax !== "number") {
        throw new Error("Invalid input, originalMin, originalMax, newMin, newMax must be numbers");
    }
    const newDistance = newMax - newMin;
    const originalDistance = originalMax - originalMin;
    if (originalDistance === 0) {
        throw new Error("Invalid input, originalMin and originalMax are the same");
    }
    if (newDistance === 0) {
        return newMin;
    }
    if (Array.isArray(input)) {
        return input.map((x) => {
            if (typeof x !== "number") {
                return 0;
            }
            return x * newDistance / originalDistance + newMin;
        });
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return input * newDistance / originalDistance + newMin;
}


export function repeat(elements, times) {
    return Array(times).fill(elements);
}


export function round(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.round(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.round(input);
}


export function sign(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.sign(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.sign(input);
}


export function sqrt(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.sqrt(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.sqrt(input);
}


export function stringCase(text, target) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    switch (target) {
        case "camelCase":
            return jsConvertCase.toCamelCase(text);
        case "snakeCase":
            return jsConvertCase.toSnakeCase(text);
        case "headerCase":
            return jsConvertCase.toHeaderCase(text);
        case "pascalCase":
            return jsConvertCase.toPascalCase(text);
        case "dotCase":
            return jsConvertCase.toDotCase(text);
        case "pathCase":
            return jsConvertCase.toPathCase(text);
        case "textCase":
            return jsConvertCase.toTextCase(text);
        case "sentenceCase":
            return jsConvertCase.toSentenceCase(text);
        case "kebabCase":
            return jsConvertCase.toKebabCase(text);
        case "lowerCase":
            return jsConvertCase.toLowerCase(text);
        case "upperCase":
            return jsConvertCase.toUpperCase(text);
        default:
            return text;
    }
}


export function stringCompare(left, right, operation, ignoreCase, ignoreWhitespace) {
    if (typeof left !== "string" || typeof right !== "string") {
        throw new Error("Invalid input, inputs must be strings");
    }
    let leftValueFinal = ignoreWhitespace ? left.replace(/\s/g, "") : left;
    leftValueFinal = ignoreCase ? leftValueFinal.toLowerCase() : leftValueFinal;
    let rightValueFinal = ignoreWhitespace ? right.replace(/\s/g, "") : right;
    rightValueFinal = ignoreCase ? rightValueFinal.toLowerCase() : rightValueFinal;
    switch (operation) {
        case "equal":
            return leftValueFinal === rightValueFinal;
        case "notEqual":
            return leftValueFinal !== rightValueFinal;
        case "includes":
            return leftValueFinal.includes(rightValueFinal);
        case "excludes":
            return !leftValueFinal.includes(rightValueFinal);
        default:
            return false;
    }
}


export function stringConcat(strings, separator, prefix, suffix) {
    prefix ??= "";
    suffix ??= "";
    const prefixHandled = handleSpecialChars(prefix);
    const suffixHandled = handleSpecialChars(suffix);
    const separatorHandled = handleSpecialChars(separator);
    if (strings.length === 0) {
        return "";
    }
    if (strings.length === 1) {
        if (Array.isArray(strings[0])) {
            if (strings[0].length === 0) {
                return "";
            }
            return prefixHandled + strings[0].join(separatorHandled) + suffixHandled;
        }
        return prefixHandled + strings[0] + suffixHandled;
    }
    return prefixHandled + strings.join(separatorHandled) + suffixHandled;
}


export function stringEndsWith(text, substring, position) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    const realPosition = position && position < 0 ? text.length + 1 : position;
    return text.endsWith(substring, realPosition);
}


export function stringIncludes(text, substring, position) {
    if (!text || !substring) {
        return false;
    }
    if (typeof text !== "string") {
        return false;
    }
    return text.includes(substring, position);
}


export function stringLength(text) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    return text.length;
}


export function stringPad(text, padstring, length, location) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    if (location === "start") {
        return text.padStart(length, padstring);
    }
    else if (location === "end") {
        return text.padEnd(length, padstring);
    }
    return text;
}


export function stringReplace(text, search, replace, replaceAll) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    if (replaceAll && search !== "") {
        if (search.startsWith("/") && search.endsWith("/")) {
            return text.replaceAll(new RegExp(search.substring(1, search.length - 1), "g"), replace);
        }
        else {
            return text.replaceAll(search, replace);
        }
    }
    else {
        if (search.startsWith("/") && search.endsWith("/")) {
            return text.replace(new RegExp(search.substring(1, search.length - 1), "gi"), replace);
        }
        else {
            return text.replace(search, replace);
        }
    }
}


export function stringSlice(text, start, end, useEnd) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    if (useEnd) {
        return text.slice(start, end);
    }
    return text.slice(start);
}


export function stringSplit(text, separator, limit) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    if (limit && limit > -1) {
        return text.split(separator, limit);
    }
    else {
        return text.split(separator);
    }
}


export function stringTemplate(strings, templateString) {
    if (!Array.isArray(strings)) {
        throw new Error("Invalid input, strings must be an array");
    }
    const flattened = [];
    strings.forEach((str) => {
        if (Array.isArray(str)) {
            flattened.push(...str);
        }
        else {
            flattened.push(str);
        }
    });
    return flattened.reduce((acc, curr, index) => {
        const placeholder = "${" + index + "}";
        return acc.replaceAll(placeholder, curr);
    }, templateString);
}


export function stringToFloat(input) {
    if (typeof input === "string") {
        return Number.parseFloat(input);
    }
    else if (typeof input === "number") {
        return input;
    }
    throw new Error(`Invalid input type, cannot parse to float: ${input}`);
}


export function stringToInt(input, radix) {
    if (typeof input !== "string" || typeof radix !== "number") {
        throw new Error("Invalid input");
    }
    if (radix === -1) {
        return Number.parseInt(input);
    }
    else if (radix < 2 || radix > 36) {
        throw new Error("Radix must be between 2 and 36");
    }
    else {
        return Number.parseInt(input, radix);
    }
}


export function stringTrim(text, location) {
    if (typeof text !== "string") {
        throw new Error("Invalid input, text must be a string");
    }
    if (location === "start") {
        return text.trimStart();
    }
    else if (location === "end") {
        return text.trimEnd();
    }
    else if (location === "both") {
        return text.trim();
    }
    return text;
}


export function switchCase(inputs, which, cases) {
    if (!Array.isArray(inputs) || inputs.length === 0) {
        return void 0;
    }
    const casesArray = cases.split(",").map((c) => c.trim()).filter((c) => c.length > 0);
    if (casesArray.length === 0) {
        return inputs[0];
    }
    const whichIndex = casesArray.indexOf(which);
    if (whichIndex === -1) {
        return inputs[0];
    }
    return inputs[whichIndex];
}


export function switchInput(input, index) {
    if (Array.isArray(input)) {
        if (index >= 0 && index < input.length) {
            return input[index];
        }
        return void 0;
    }
    return input;
}


export function toExponential(input, fractionDigits) {
    if (typeof input !== "number") {
        throw new Error("Invalid input");
    }
    if (fractionDigits === -1) {
        return input.toExponential();
    }
    if (fractionDigits < 0 || fractionDigits > 20) {
        throw new Error("Fraction digits must be between 0 and 20");
    }
    return input.toExponential(fractionDigits);
}


export function toFixed(input, precision) {
    if (typeof input !== "number" || typeof precision !== "number") {
        throw new Error("Invalid input");
    }
    if (precision < 0 || precision > 20) {
        throw new Error("Precision must be between 0 and 20");
    }
    return input.toFixed(precision);
}


export function toPrecision(input, precision) {
    if (typeof input !== "number" || typeof precision !== "number") {
        throw new Error("Invalid input");
    }
    if (precision < 1 || precision > 21) {
        throw new Error("Precision must be between 1 and 21");
    }
    return input.toPrecision(precision);
}


export function toString(input, radix) {
    if (typeof input !== "number" || typeof radix !== "number") {
        throw new Error("Invalid input");
    }
    if (radix === 10) {
        return input.toString();
    }
    if (radix < 2 || radix > 36) {
        throw new Error("Radix must be between 2 and 36");
    }
    return input.toString(radix);
}


export function truncate(input) {
    if (Array.isArray(input)) {
        return input.map((x) => typeof x === "number" ? Math.trunc(x) : 0);
    }
    if (typeof input === "object") {
        throw new Error("Invalid input, object not supported");
    }
    if (typeof input !== "number") {
        throw new Error("Invalid input, input must be a number");
    }
    return Math.trunc(input);
}


export function typeOf(input) {
    if (!input) {
        return "";
    }
    let type = typeof input;
    if (type === "object") {
        if (input === null) {
            type = "null";
        }
        else if (Array.isArray(input)) {
            type = "array";
        }
        else if (input instanceof Date) {
            type = "date";
        }
        else if (input instanceof RegExp) {
            type = "regexp";
        }
        else if (input instanceof Function) {
            type = "function";
        }
        else if (input instanceof Error) {
            type = "error";
        }
    }
    if (React.isValidElement(input)) {
        type = "react.element";
    }
    return type;
}


export async function unzipAsset(file, outputType = "text") {
    const { entries } = await unzip(file);
    const finalMap = {};
    await Promise.all(Object.values(entries).map(async (entry) => {
        if (outputType === "blob") {
            finalMap[entry.name] = await entry.blob();
        }
        else if (outputType === "arrayBuffer") {
            finalMap[entry.name] = await entry.arrayBuffer();
        }
        else if (outputType === "text") {
            finalMap[entry.name] = await entry.text();
        }
        else if (outputType === "json") {
            finalMap[entry.name] = await entry.json();
        }
    }));
    return finalMap;
}
